@font-face {
  font-family: 'CookieRunBlack';
  src: url('/fonts/CookieRunBlack.ttf');
}
@font-face {
  font-family: 'CookieRunBold';
  src: url('/fonts/CookieRunBold.ttf');
}
@font-face {
  font-family: 'DigitalDreamNarrow';
  src: url('/fonts/DigitalDreamNarrow.ttf');
}
.common {
  background-color: #87c37d;
}
.unusual {
  background-color: #8ad8ee;
}
.noteworthy {
  background-color: #f4aeecff;
}
.remarkable {
  background-color: #c388d8;
}
.extraordinary {
  background-color: #f1c447;
}
.secondary {
  color: #fec85d;
}
.notRounded {
  border-radius: 0 !important;
}
.rounded {
  border-radius: 20px !important;
  overflow: hidden;
}
img {
  border-radius: 20px !important;
  overflow: hidden;
}
a {
  color: #87c37d;
}
a:visited,
a:hover {
  color: #87c37d;
}
.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.verticalCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body {
  background-color: #f9f7f7;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: #555450;
}
.container-fluid {
  padding: 0;
}
h1 {
  font-family: 'CookieRunBlack', sans-serif;
  font-weight: 900;
  font-size: 3rem;
  color: #fec85d;
  margin-bottom: 20px;
}
h2,
h3,
h4,
h5 {
  font-family: 'CookieRunBold', sans-serif;
  font-weight: 700;
  color: #fec85d;
}
p {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 1.2rem;
}
.baseContainer {
  padding-top: 40px;
  padding-bottom: 40px;
}
.raisedContentContainer {
  border-radius: 20px !important;
  overflow: hidden;
  background-color: white;
  padding: 40px;
}
.contentBox {
  padding: 40px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: #87c37d;
  color: #f9f7f7;
}
.clickable {
  font-family: 'CookieRunBold', sans-serif;
  font-weight: 700;
  background-color: #fec85d;
  color: #f9f7f7;
  font-size: 20px;
  border: 0px;
}
.clickable:hover {
  text-decoration: none;
  background-color: #feb72a;
  color: #f9f7f7;
}
.clickable:visited {
  color: #f9f7f7;
}
.clickable.primary {
  background-color: #87c37d;
}
.clickable:focus,
.clickable:active {
  background-color: #fec85d;
  outline: none !important;
  box-shadow: none;
}
.buttonLink {
  border-radius: 20px !important;
  overflow: hidden;
  font-family: 'CookieRunBold', sans-serif;
  font-weight: 700;
  background-color: #fec85d;
  color: #f9f7f7;
  font-size: 20px;
  border: 0px;
  margin: 0 auto;
  display: block;
  width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.buttonLink:hover {
  text-decoration: none;
  background-color: #feb72a;
  color: #f9f7f7;
}
.buttonLink:visited {
  color: #f9f7f7;
}
.buttonLink.primary {
  background-color: #87c37d;
}
.buttonLink:focus,
.buttonLink:active {
  background-color: #fec85d;
  outline: none !important;
  box-shadow: none;
}
.homeContainer {
  text-align: center;
  width: 100%;
  margin-top: -105px;
}
@media (max-width: 1200px) {
  .homeContainer {
    margin-top: 0px;
  }
}
.homeContainer section {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 575.98px) {
  .homeContainer section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.homeContainer section.interspersedInfo {
  min-height: 320px;
  background-color: #87c37d;
  color: #fec85d;
}
.homeContainer section.interspersedInfo.secondary {
  background-color: #fec85d;
  color: #87c37d;
  height: 40px;
  min-height: 0px;
  padding: 0;
}
.homeContainer section.interspersedInfo.secondary p {
  margin: 0;
}
.homeContainer .fullWidthImageContainer {
  margin: 0;
  padding: 0;
  height: 80vh;
  width: 100%;
}
.homeContainer .fullWidthImageContainer img {
  border-radius: 0 !important;
  height: 80vh;
  width: 100%;
  object-fit: cover;
}
.homeContainer .navContainerFooter {
  max-width: 460px;
  margin: 0 auto;
}
.homeContainer #spring {
  margin: 0;
  padding: 0;
}
.homeContainer #spring .bannerContainer {
  position: relative;
}
.homeContainer #spring .bannerContainer .logoNameContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.homeContainer #spring .bannerContainer .logoNameContainer .logo {
  margin-top: 20px;
  width: 60%;
  max-width: 650px;
  height: auto;
}
@media (max-width: 900px) {
  .homeContainer #spring .bannerContainer .logoNameContainer .logo {
    width: 100%;
    margin-top: 40px;
  }
}
.homeContainer #anidrone {
  position: fixed;
  z-index: 10;
  bottom: 40px;
  left: 40px;
  width: 80%;
  height: auto;
  max-width: 400px;
  max-height: 400px;
  animation-name: enter, hover;
  animation-duration: 3s, 3s;
  animation-delay: 0s, 3s;
  animation-iteration-count: 10, infinite;
  animation-timing-function: ease-out, linear;
}
@media (max-width: 450px) {
  .homeContainer #anidrone {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 300px;
  }
}
@keyframes enter {
  0% {
    transform: scale(0) translate(1000%, -500%) rotateZ(0deg) rotateZ(-20deg);
  }
  100% {
    transform: scale(1) translate(0%, 0%) rotateZ(0deg);
  }
}
@keyframes hover {
  0% {
    transform: translate(0em, 0em) rotateZ(0deg);
  }
  25% {
    transform: translate(0.1em, 0.2em) rotateZ(-1deg);
  }
  50% {
    transform: translate(0em, -0.2em) rotateZ(-2deg);
  }
  75% {
    transform: translate(-0.1em, 0.4em) rotateZ(-1deg);
  }
  100% {
    transform: translate(0em, 0em) rotateZ(0deg);
  }
}
.homeContainer #anidrone .drone {
  width: 100%;
  height: auto;
  z-index: 12;
}
.homeContainer #anidrone .propeller {
  position: absolute;
  width: 20%;
  height: 20%;
  bottom: 10%;
  z-index: -11;
  animation-name: spin;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.homeContainer #anidrone .propeller.left {
  left: 4%;
}
.homeContainer #anidrone .propeller.right {
  right: 4%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.homeContainer #anidrone .propeller img {
  width: 100%;
  height: 100%;
}
.homeContainer #anidrone p {
  position: absolute;
  left: 15%;
  right: 15%;
  top: 20%;
  font-size: 1.1em;
  overflow: hidden;
  white-space: pre-wrap;
}
@media (max-width: 450px) {
  .homeContainer #anidrone p {
    top: 15%;
    font-size: 0.8em;
  }
}
.homeContainer #anidrone p,
.homeContainer #anidrone span {
  font-family: 'DigitalDreamNarrow', sans-serif;
  color: #808080;
  font-weight: 700;
  opacity: 0.6;
  animation: flicker 0.1s infinite;
}
@keyframes flicker {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.9;
  }
}
.homeContainer #anidrone .eyes {
  animation: eyeFade 1s infinite;
}
@keyframes eyeFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.homeContainer #anidrone span {
  font-family: 'DigitalDreamNarrow', sans-serif;
  position: absolute;
  bottom: 34%;
  left: 10%;
  right: 10%;
  font-size: 0.6em;
}
.homeContainer .announcements {
  min-height: 90px;
  background-color: #fec85d;
  padding: 30px;
}
.homeContainer .announcements h1 {
  font-family: 'CookieRunBold', sans-serif;
  font-weight: 700;
  color: #f9f7f7;
  font-size: 1.5em;
  margin: 0;
}
.homeContainer .announcements h1 span {
  padding-left: 5px;
  padding-right: 5px;
}
.homeContainer #autumn {
  position: relative;
}
.homeContainer #autumn img {
  position: relative;
  z-index: -2;
}
.homeContainer .nftCarousel {
  height: 320px;
}
.homeContainer #roadmap {
  background-color: #8ad8ee;
  padding: 180px;
  height: auto;
  position: relative;
  overflow: hidden;
}
.homeContainer #roadmap .roadmapDisplay {
  animation: flicker 0.1s infinite;
}
@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.93;
  }
  100% {
    opacity: 1;
  }
}
@keyframes sailMove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
@media (max-width: 700px) {
  .homeContainer #roadmap {
    padding: 120px;
  }
  @keyframes sailMove {
    0% {
      transform: translate(0, -50%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}
.homeContainer #roadmap .gridBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background-size: 40px 40px;
  background-image: linear-gradient(to right, #f9f7f7 1px, transparent 1px), linear-gradient(to bottom, #f9f7f7 1px, transparent 1px);
  opacity: 0.3;
  animation: sailMove 18s linear 0.5s infinite;
}
.homeContainer #roadmap p {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 98%;
  margin: 0;
  text-align: left;
  color: #f9f7f7;
  font-weight: 900;
}
.homeContainer #roadmap .swimlane {
  position: absolute;
  width: 100%;
  height: 50px;
  left: 0;
}
.homeContainer #roadmap .swimlane.one {
  top: 108px;
}
.homeContainer #roadmap .swimlane.two {
  bottom: 108px;
}
@media (max-width: 700px) {
  .homeContainer #roadmap .swimlane {
    width: 100px;
    height: 100%;
  }
  .homeContainer #roadmap .swimlane.one {
    top: 0;
    bottom: 0;
    left: 10px;
    right: auto;
  }
  .homeContainer #roadmap .swimlane.two {
    top: 0;
    bottom: 0;
    left: auto;
    right: 10px;
  }
}
.homeContainer #roadmap .swimlane span {
  position: absolute;
  top: 0;
  text-align: center;
  right: -200px;
  color: #f9f7f7;
  font-size: 1.1em;
  font-weight: 500;
  max-width: 250px;
  opacity: 0;
  animation: itemMove 22s linear 0.5s 1;
}
@keyframes itemMove {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  99% {
    transform: translate(-120vw, 0);
    opacity: 0;
  }
  100% {
    display: none;
  }
}
@media (max-width: 700px) {
  .homeContainer #roadmap .swimlane span {
    top: initial;
    right: 0px;
    top: -100px;
    font-size: 0.8em;
    animation: itemMove 21s linear 0.5s 1;
  }
  @keyframes itemMove {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    99% {
      transform: translate(0, 120vh);
      opacity: 0;
    }
    100% {
      display: none;
    }
  }
}
.homeContainer #roadmap .blueprint {
  width: 80%;
  height: auto;
  overflow: hidden;
}
@media (max-width: 700px) {
  .homeContainer #roadmap .blueprint.desktopBlueprint {
    display: none;
  }
}
.homeContainer #roadmap .blueprint.mobileBlueprint {
  width: 100%;
}
@media (min-width: 700px) {
  .homeContainer #roadmap .blueprint.mobileBlueprint {
    display: none;
  }
}
.homeContainer #cta {
  margin: 0;
  background-color: #87c37d;
  color: #fec85d;
}
.homeContainer #cta p {
  margin-top: 10px;
  margin-bottom: 20px;
}
.homeContainer #cta .input-group {
  width: 70%;
  margin: 0 auto;
  border-radius: 20px !important;
  overflow: hidden;
}
.homeContainer #cta .email {
  color: #87c37d;
  height: 50px;
  border: 0px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 0.9em;
  padding-left: 10px;
  text-align: center;
}
.homeContainer #cta .email:focus {
  outline: none;
}
.homeContainer #cta .button {
  height: 100%;
  border: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.homeContainer #cta .socialLinks {
  color: #fec85d;
  padding-top: 40px;
  font-size: 2.3em;
  margin: 0 auto;
}
.homeContainer #cta .socialLinks a {
  color: #fec85d;
}
.homeContainer #cta .socialLinks a:hover {
  color: #f3a202;
}
.homeContainer #footer {
  padding: 40px;
}
@media (max-width: 700px) {
  .homeContainer #footer {
    margin-bottom: 300px;
  }
}
.homeContainer #footer p {
  font-size: 1em;
}
.teamContainer {
  text-align: center;
}
.teamContainer .personContainer {
  margin-top: 20px;
  margin-bottom: 40px;
}
.teamContainer .personContainer h4 {
  color: #87c37d;
}
.teamContainer .personContainer img {
  width: 50%;
  max-width: 200px;
  height: auto;
  margin-top: 10px;
}
.teamContainer .personContainer p {
  font-size: 1em;
  margin-bottom: 10px;
}
.teamContainer .personContainer a {
  color: #87c37d;
}
.teamContainer .personContainer a :visited {
  color: #87c37d;
}
.intelContainer {
  text-align: center;
}
.intelContainer section {
  margin-bottom: 40px;
}
.intelContainer #faq .faqList .question {
  cursor: pointer;
  user-select: none;
  font-size: larger;
  font-weight: 700;
  font-size: 1.2em;
}
.intelContainer #faq .faqList .answer {
  font-size: 1em;
}
.intelContainer #faq .faqList .linkImage {
  height: 150px;
  float: right;
}
.intelContainer #faq .faqList a {
  display: block;
}
.intelContainer #faq .faqList a.withLink {
  float: left;
  margin-top: 55px;
  margin-left: 20px;
}
.intelContainer #causes .percentageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.intelContainer #causes .percentageContainer h2 {
  font-size: 10em;
}
@media (max-width: 575.98px) {
  .intelContainer #causes .percentageContainer h2 {
    font-size: 7em;
  }
}
.intelContainer #causes .percentageContainer p {
  margin: 0;
}
.intelContainer #causes .separator {
  width: 70%;
  height: 1px;
  background-color: #f9f7f7;
  margin: 40px;
}
.nftsContainer {
  text-align: center;
}
@media (min-width: 700px) {
  .nftsContainer {
    padding-top: 90px;
  }
}
@media (max-width: 700px) {
  .nftsContainer {
    padding-top: 20px;
  }
}
.nftsContainer .carouselContainer {
  margin: 0 auto;
  padding: 0;
}
.nftsContainer .carouselContainer .carousel .carousel-indicators li {
  background-color: #87c37d;
}
.nftsContainer .carouselContainer .carousel .controlIcon {
  color: #fec85d;
  font-size: 30px;
}
.nftsContainer .carouselContainer .carousel .carouselItem {
  min-height: 550px;
  padding-bottom: 40px;
}
.nftsContainer h2 {
  margin-bottom: 20px;
}
.nftsContainer .rarityContainer {
  margin-top: 60px;
}
.nftsContainer .rarityContainer .rarityLevelsContainer {
  display: flex;
  border-radius: 20px !important;
  overflow: hidden;
}
.nftsContainer .rarityContainer .rarityLevelsContainer .levelBox {
  flex: 1;
}
.nftsContainer .rarityContainer .rarityLevelsContainer .levelBox p {
  color: #f9f7f7;
}
@media (max-width: 990px) {
  .nftsContainer .rarityContainer .rarityLevelsContainer .levelBox p {
    font-size: 0.7em;
  }
}
@media (max-width: 500px) {
  .nftsContainer .rarityContainer .rarityLevelsContainer .levelBox p {
    font-size: 0.6em;
  }
}
.nftsContainer .typesContainer {
  margin-top: 60px;
  margin-bottom: 60px;
}
.nftsContainer .typesContainer h4 {
  margin-bottom: 10px;
}
.nftsContainer .typesContainer img {
  width: 100%;
  height: auto;
}
.nftsContainer .typesContainer p {
  margin-top: 10px;
}
.nftsContainer .utilityContainer {
  margin-top: 40px;
}
.nftsContainer .utilityContainer .utilityBox img {
  width: 80px;
  height: auto;
  margin: 0 auto;
  margin: 20px;
}
.nftsContainer .utilityContainer .utilityBox h5 {
  color: #87c37d;
  text-transform: uppercase;
}
.nftsContainer .showcase img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.nftsContainer .showcase.rotating {
  margin-top: 40px;
}
.nftsContainer .showcase.bottom {
  margin-bottom: 0;
}
.storeContainer {
  text-align: center;
  background-color: #f9f7f7;
}
.storeContainer .raisedContentContainer {
  margin-bottom: 40px;
}
.storeContainer h1 {
  margin-bottom: 40px;
}
.storeContainer .product {
  margin-bottom: 40px;
}
.storeContainer .product .productCarousel {
  border-radius: 20px !important;
  overflow: hidden;
  transform: rotate(90deg);
}
.storeContainer .product .productCarousel .imageContainer {
  transform: rotate(-90deg);
  width: 100%;
  max-width: 500px;
  height: auto;
  position: relative;
}
.storeContainer .product .productCarousel .imageContainer .floating {
  display: table;
  float: left;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0%;
  -webkit-transition: opacity 0.25s linear;
  -moz-transition: opacity 0.25s linear;
  -o-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear;
}
.storeContainer .product .productCarousel .imageContainer .floating .band {
  position: absolute;
  top: 41%;
  right: 0px;
  width: 100%;
  height: 50px;
  background-color: #f9f7f7;
}
.storeContainer .product .productCarousel .imageContainer .floating .band a {
  display: block;
  font-weight: 900;
  color: #fec85d;
  margin-top: 12px;
}
.storeContainer .product .productCarousel .imageContainer .floating .band a:hover {
  text-decoration: none;
}
.storeContainer .product .productCarousel .imageContainer img {
  border-radius: 0 !important;
  height: 100%;
  width: 100%;
}
.storeContainer .product .touchLink {
  font-size: 0.9em;
}
.storeContainer .productItem img {
  width: 100%;
  height: auto;
}
.storeContainer .productItem .buttonLink {
  margin-top: 20px;
}
.storeProductItemContainer img {
  width: 100%;
  height: auto;
}
.storeProductItemContainer p {
  white-space: pre-wrap;
}
.storeProductItemContainer .buttonLink {
  margin: 0 auto;
  display: block;
  width: 180px;
}
.storeProductItemContainer .prices {
  font-size: 1em;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}
.paymentSuccessContainer .buttonLink {
  margin: 0 auto;
  display: block;
  width: 250px;
}
.rangerBuilderContainer {
  margin-top: 40px;
}
.rangerBuilderContainer .controls {
  border-radius: 20px !important;
  overflow: hidden;
  font-size: 2em;
  display: flex;
  justify-content: space-between;
  background-color: #87c37d;
  color: #fec85d;
  width: 200px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.rangerBuilderContainer .controls .controlButton {
  cursor: pointer;
}
.rangerBuilderContainer .traitSelectionBox {
  border-radius: 20px !important;
  overflow: hidden;
  background-color: #87c37d;
  color: #fec85d;
  padding: 10px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.rangerBuilderContainer .traitSelectionBox .leftArrow,
.rangerBuilderContainer .traitSelectionBox .rightArrow {
  font-size: 25px;
  margin-top: -4px;
  cursor: pointer;
}
.rangerBuilderContainer .traitSelectionBox .name {
  font-weight: 900;
  font-size: 1em;
  line-height: 1em;
  margin: 0;
  text-align: center;
}
.rangerBuilderContainer .traitSelectionBox .name .nameTitle {
  color: #f9f7f7;
  font-size: 0.9em;
}
.rangerBuilderContainer .traitSelectionBox .name .nameName {
  font-size: 0.8em;
}
.rangerBuilderContainer .colourPickerBox {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0;
}
.rangerBuilderContainer .colourPickerBox .colourPicker {
  width: 22px;
  height: 22px;
  border-radius: 20px;
  border: 1px solid black;
  margin: 2px;
  display: inline-block;
  cursor: pointer;
}
.rangerBuilderContainer .colourPickerBox .colourPicker.selected {
  width: 27px;
  height: 27px;
}
.rarityCalculator {
  text-align: center;
}
.rarityCalculator h2 {
  margin-bottom: 40px;
}
.rarityCalculator .row {
  margin-top: 0;
  margin-bottom: 20px;
}
.speciesListContainer {
  text-align: center;
}
.speciesListContainer .speciesGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 60px;
}
.speciesListContainer .speciesGrid .speciesGridBox {
  border-radius: 20px !important;
  overflow: hidden;
  width: 120px;
  height: 120px;
}
.speciesListContainer .speciesGrid .speciesGridBox img {
  width: 100%;
  height: 100%;
}
.speciesContainer p {
  margin-top: 10px;
  white-space: pre-wrap;
}
.speciesContainer .backToSpeciesContainer {
  margin-top: 20px;
}
.speciesContainer .mainCharacterImageContainer {
  padding: 84px;
}
.speciesContainer .mainCharacterImageContainer img {
  width: 100%;
  height: auto;
}
.navbar {
  z-index: 1;
  padding: 20px;
}
.navbar .navbar-toggler {
  border: 0px;
  margin-bottom: 10px;
}
.navContainer {
  border-radius: 20px !important;
  overflow: hidden;
  background-color: #fec85d;
  padding: 15px;
}
.navContainer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.navContainer ul li {
  display: inline-block;
}
.navContainer ul li a {
  font-family: 'CookieRunBold', sans-serif;
  font-weight: 700;
  font-size: 1.05em;
  color: #f9f7f7;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}
.imageCarouselContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.imageCarouselContainer .imageCarousel {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.imageCarouselContainer .carouselBanner {
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  animation: bannermove 32s linear 0.5s infinite;
}
.imageCarouselContainer .carouselBanner img {
  height: 100%;
  width: auto;
  margin: 0 0.5em;
}
@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
